<template>
  <div :class="['container', {'container--submit': submit}]">
    <span v-if="tooltip !== ''" class="tooltip" v-html="tooltip"></span>
    <button type="button"
    :value="this.content"
    :name="this.content"
    :tooltipText="this.tooltip"
    :class="['btn', {'btn--small': small}, {'btn--white': white}, {'btn--gray': gray}, {'btn--submit': this.submit}]" @click="emitClickEvent"
    :disabled="this.disabledButton">
      <span class="content" v-show="!ongoingState">{{ content }}</span>
      <div v-show="ongoingState" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
    },
    gray: {
      type: Boolean,
    },
    white: {
      type: Boolean,
    },
    submit: {
      type: Boolean,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ongoingState: this.saving,
      disabledButton: this.disabled,
    };
  },
  methods: {
    emitClickEvent() {
      this.$emit('click');
    },
  },
  watch: {
    saving: function() {
      this.ongoingState = this.saving;
      if (this.disabled !== true) {
        this.disabledButton = this.saving;
      }
    },
    disabled: function() {
      this.disabledButton = this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;

  &--submit {
    text-align: center;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}
.tooltip {
  display: none;
  bottom: 60px;
  min-width: 200px;
  font-weight: 500;
  font-style: italic;
  position: absolute;
  padding: 10px 20px;
  background: #1067FF;
  color: #fff;
  font-size: 14px;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #1067FF #fff #fff #fff;
    bottom: -20px;
    left: 55px;
    -webkit-transition: 0s;
    transition: 0s;
  }
}
.btn {
  color: $primary-color;
  font-size: rem-calc(14px);
  border-radius: 27.5px;
  border: 1px solid $gray-color;
  padding: 5px 10px;
  outline: none;
  position: relative;
  @include desktop {
    padding: 10px 30px;
    font-size: rem-calc(20px);
  }

  &--small {
    font-size: rem-calc(15px);
    padding: 15px 20px;
  }

  &--gray {
    border: none;
    color: #494848;
    background: #EFEFEF;
  }

  &--white {
    border-color: #fff;
    color: #fff;
  }

  &--submit {
    margin-top: 50px;
  }
}
.content {
  white-space: nowrap;
}
</style>
